.navigation-container {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 2px;
    width: 100vw;
    min-height: 4rem;
    height: fit-content;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    z-index: 2;
    height: 4rem;
    background: white;
  
    .content {
      width: 100%;
      padding: 0 2rem;
      max-width: 1440px;
  
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 0.8rem;
  
      .logo {
          height: 2rem;
      }
  
      .menu-toggle {
        display: flex;
        flex-direction: column;
        background: none;
        border: none;
        cursor: pointer;
        box-shadow: none;
        padding: 0;
        height: unset;
        margin-bottom: 0;
  
        .bar {
          width: 1.5rem;
          height: 0.15rem;
          background-color: #333;
          margin: 0.15rem 0;
          transition: transform 1s ease;
        }
  
        &.open .bar {
          &:nth-child(1) {
            transform: rotate(-45deg) translate(-0.1rem, 0.1rem);
          }
  
          &:nth-child(2) {
            opacity: 0;
          }
  
          &:nth-child(3) {
            transform: rotate(45deg) translate(-0.5rem, -0.52rem);
          }
        }
      }
  
      .extra-button {
        background-color: #ee4d2d;
        border: none;
        border-radius: 2rem;
        color: white;
        cursor: pointer;
        font-weight: 300;
        font-size: 1rem;
        margin-bottom: 0;
        padding: 0.5rem 1rem;
        text-transform: none;
      }
  
      .dropdown {
        color: #000;
        overflow: hidden;
        cursor: pointer;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: visible;
  
        .dropdown-content {
          position: absolute;
          background-color: white;
          min-width: 180px;
          box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
          z-index: 1;
          top: 4rem;
          max-height: 0;
          transition: max-height 0.2s ease-out;
          overflow: hidden;
          left: 0;
  
          a {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 1.2rem 1.4rem;
          }
  
          &.open {
            max-height: 600px;
            transition: max-height 0.3s ease-in;
  
            a:hover {
              background: #fef9f8;
            }
          }
        }
  
        .dropdown-arrow {
          transition: all 0.4s ease-in-out;
          transform: rotate(180deg);
          display: flex;
          align-items: center;
          justify-content: center;
  
          svg {
            width: 0.9rem;
          }
  
          &.open {
            transform: rotate(0deg);
          }
        }
      }
  
      .menu-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 2rem;
        row-gap: 1rem;
        height: 100%;
  
        a {
          font-size: 0.9rem;
        }
  
        .menu-item {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          font-size: 0.9rem;
          font-weight: 400;
  
          &.active {
            color: #ee4d2d;
  
            .dropdown-arrow {
              svg path {
                fill: #ee4d2d;
                fill-opacity: 1;
              }
            }
          }
        }
  
        &-phone {
          background: rgba(0, 0, 0, 0.5);
          position: absolute;
          top: 4rem;
          right: 0;
          width: 0;
          height: calc(100vh - 4rem);
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
  
          .menu-container {
            background: white;
            position: absolute;
            right: 0;
            width: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            row-gap: 0.8rem;
            padding: 1.2rem;
            transform: translateX(500px);
            opacity: 0;
            transition: transform 0.6s ease-in-out, width 0.6s ease-in-out,
              opacity 0.6s ease-in-out;
            z-index: 2;
            font-size: 0.9rem;
            overflow: auto;
  
            div {
              font-weight: 400;
            }
  
            a {
              padding: 0.8rem;
              font-weight: 400;
  
              &.active {
                background: #fff4f4;
              }
            }
  
            .line {
              border: 0.5px solid rgba(0, 0, 0, 0.09);
            }
  
            .extra-button {
              font-size: 0.9rem;
              padding: 0.6rem;
              width: 100%;
              text-align: center;
            }
          }
  
          &.open {
            pointer-events: auto;
            opacity: 1;
            width: 100%;
  
            .menu-container {
              transform: translateX(0);
              opacity: 1;
              width: 70vw;
              max-width: 250px;
            }
          }
  
          .dropdown {
            height: fit-content;
            flex-direction: column;
            justify-content: start;
            align-items: flex-start;
  
            .menu-item {
              padding: 0.8rem;
              display: flex;
              gap: 0.5rem;
              align-items: center;
  
              &.active {
                color: #ee4d2d;
  
                .dropdown-arrow {
                  svg path {
                    fill: #ee4d2d;
                    fill-opacity: 1;
                  }
                }
              }
            }
  
            .dropdown-content {
              position: unset;
              min-width: 100px;
              height: 0;
              overflow: hidden;
              box-shadow: none;
              width: 100%;
  
              a {
                display: flex;
                justify-content: start;
                padding-left: 1rem;
                width: 100%;
                padding: 0.4rem;
                padding-left: 1rem;
              }
  
              &.open {
                height: fit-content;
              }
            }
          }
        }
      }
  
      a {
        color: rgba(#000, 0.87);
        text-decoration: none;
  
        display: flex;
        align-items: center;
  
        &.active {
          color: #ee4d2d;
        }
  
        svg {
          height: 3rem;
          display: flex;
        }
      }
    }
}
  
@media screen and (max-width: 992px) {
    .mobile-container {
        display: block;
    }

    .navigation-container {
        .content {
        padding: 0 5rem;

        .menu-list {
            &.desktop-container {
            display: none;
            }
        }
        }
    }
}

@media screen and (min-width: 993px) {
    .mobile-container {
        display: none;
    }

    .desktop-container {
        display: block;
    }
}

@media screen and (max-width: 576px) {
    .navigation-container > .content {
        padding: 0 2rem;
    }
}

@media screen and (max-width: 500px) {
    .menu-toggle {
    padding: 0 !important;
    }
}
@primary-color: rgb(238, 77, 45);